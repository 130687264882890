<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>项目配置</a-breadcrumb-item>
    <a-breadcrumb-item>项目管理</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 名称 -->
    <span class="tool-title">名称：</span>
    <a-input v-model:value="search" style="width: 160px;" placeholder="请输入" />
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="handleSearch">查询</a-button>
    <a-button style="margin-left: 16px;" @click="handleReset">重置</a-button>
    <div style="flex: 1;"></div>
    <a-button type="primary" @click="add()"><plus-outlined />新增项目</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 启用状态 -->
      <template v-if="column.key === 'status'">
        <a-switch
          v-model:checked="record.status"
          checked-children="开"
          un-checked-children="关"
          @change="statusChange($event, record)"
        />
      </template>
      <!-- 首页AB版 -->
      <template v-if="column.key === 'ab_show'">
        <a-switch
          v-model:checked="record.ab_show"
          checked-children="开"
          un-checked-children="关"
          @change="statusChangeAB($event, record)"
        />
      </template>
      <!-- 网赚模式 -->
      <template v-if="column.key === 'is_wz_close'">
        <a-switch
          v-model:checked="record.is_wz_close"
          checked-children="开"
          un-checked-children="关"
          @change="statusChangeWz($event, record)"
        />
      </template>
      <!-- 项目属性 -->
      <template v-if="column.key === 'type'">
        {{ (ProjectAttrs.find(item => item.value === record.type) || {}).text }}
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <a class="operation-item" @click="add(record)">设置</a>
        <a class="operation-item" @click="develop(record)">开发配置</a>
      </template>
    </template>
  </a-table>
  <!-- 新增、编辑项目设置 -->
  <Add ref="RefAdd" @success="getProjectList"></Add>
  <!-- 开发设置 -->
  <Developer ref="RefDeveloper" @success="getProjectList"></Developer>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import Add from './components-projects/Add'
import Developer from './components-projects/Developer'
import { message } from 'ant-design-vue'
import { projectList, projectStatus, projectAbShow, projectWzShow } from '@/api/system'
import { ProjectAttrs } from '@/utils/constantList'

// 新增、编辑组件实例
let RefAdd = ref(null)
// 开发设置组件实例
let RefDeveloper = ref(null)
// 加载
let isLoading = ref(false)
// 搜索
let search = ref(undefined)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源（付费转化率、权重本期不做）
let columns = ref([
  {
    title: '项目ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: '项目名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '项目属性',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: '客户端',
    dataIndex: 'platform',
    key: 'platform'
  },
  {
    title: '创建日期',
    dataIndex: 'created_date',
    key: 'created_date'
  },
  {
    title: '启用状态',
    dataIndex: 'status',
    key: 'status'
  },
  // {
  //   title: '首页AB版',
  //   dataIndex: 'ab_show',
  //   key: 'ab_show'
  // },
  {
    title: '网赚模式',
    dataIndex: 'is_wz_close',
    key: 'is_wz_close'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取项目列表
  getProjectList()
})

// 获取项目列表
function getProjectList () {
  isLoading.value = true
  const params = {
    kw: search.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  projectList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data.data
      list.forEach(item => {
        item.status = Boolean(item.status)
        item.ab_show = Boolean(item.ab_show)
        item.is_wz_close = Boolean(item.is_wz_close)
      })
      dataSource.value = list
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getProjectList()
}

// 新增、编辑项目设置
function add (record) {
  RefAdd.value.showDrawer(record || {})
}

// 开发设置
function develop (record) {
  RefDeveloper.value.showDrawer(record)
}

// 查询
function handleSearch () {
  pagination.current = 1
  // 获取列表
  getProjectList()
}

// 重置
function handleReset () {
  // 筛选项重置
  search.value = null
  // 分页重置
  pagination.current = 1
  getProjectList()
}

// 切换启用状态
function statusChange (e, record) {
  isLoading.value = true
  const params = {
    project_id: record.id,
    status: Number(e)
  }
  projectStatus(params).then(res => {
    // isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      if (e) {
        message.success('开启成功')
      } else {
        message.success('关闭成功')
      }
    } else {
      message.error(res.message || msg)
    }
    getProjectList()
  }).catch(() => {
    // isLoading.value = false
    getProjectList()
  })
}

// 切换首页AB版
function statusChangeAB (e, record) {
  isLoading.value = true
  const params = {
    project_id: record.id,
    ab_show: Number(e)
  }
  projectAbShow(params).then(res => {
    // isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      if (e) {
        message.success('开启成功')
      } else {
        message.success('关闭成功')
      }
    } else {
      message.error(res.message || msg)
    }
    getProjectList()
  }).catch(() => {
    // isLoading.value = false
    getProjectList()
  })
}

// 切换网赚模式
function statusChangeWz (e, record) {
  isLoading.value = true
  const params = {
    project_id: record.id,
    is_wz_close: Number(e)
  }
  projectWzShow(params).then(res => {
    // isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      if (e) {
        message.success('开启成功')
      } else {
        message.success('关闭成功')
      }
    } else {
      message.error(res.message || msg)
    }
    getProjectList()
  }).catch(() => {
    // isLoading.value = false
    getProjectList()
  })
}
</script>

<style scoped>
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.table-view {
  margin-top: 20px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>