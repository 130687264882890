<template>
  <a-drawer
    width="700"
    :title="'开发配置' + `【${name}】`"
    :closable="false"
    :visible="visible"
    :footer="null"
    @close="onClose"
  >
    <a-tabs
      v-if="visible"
      v-model:activeKey="activeKey"
      type="card"
      class="tabs-wrap"
    >
      <!-- 每个平台tab -->
      <a-tab-pane
        v-for="(item, index) in Platforms"
        :key="item.value"
        :tab="item.text"
      >
        <!-- 配置页面 -->
        <DevConfig
          :project="id"
          :platform="item.value"
        ></DevConfig>
      </a-tab-pane>
      <!-- 单独新增PC -->
      <!-- <a-tab-pane tab="PC" :key="7" v-if="$pub.ljProjectIDs().includes(id)">
        <DevConfig
          :project="id"
          :platform="7"
        ></DevConfig>
      </a-tab-pane> -->
    </a-tabs>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose } from 'vue'
import { Platforms } from '@/utils/constantList'
import DevConfig from './DevConfig'

// emit
const emit = defineEmits(['success'])
// activeKey
let activeKey = ref(Platforms[0].value)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// id
let id = ref(null)
// name
let name = ref(null)

// 打开抽屉
function showDrawer (params) {
  // 展开
  visible.value = true
  // id
  id.value = params.id
  // name
  name.value = params.name
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 表单重置
    visible.value = false
  }
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.kuaishou-module-title {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: bold;
}
</style>